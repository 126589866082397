import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, mergeProps as _mergeProps, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1318b174"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "area" }
const _hoisted_2 = ["element-loading-text"]
const _hoisted_3 = ["element-loading-text"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_GridTable = _resolveComponent("GridTable")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "first",
      "element-loading-text": _ctx.$t('mainLoadMsg')
    }, [
      _createVNode(_component_el_input, {
        onInput: _cache[0] || (_cache[0] = e => _ctx.treeParam.filterText = _ctx.valid(e)),
        modelValue: _ctx.treeParam.filterText,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.treeParam.filterText) = $event)),
        placeholder: _ctx.$t('area.searchTip')
      }, null, 8, ["modelValue", "placeholder"]),
      _createVNode(_component_el_tree, {
        ref: el=>_ctx.refMap.set('treeRef',el),
        data: _ctx.treeParam.treeData,
        "node-key": "id",
        props: _ctx.treeParam.defaultProps,
        onNodeClick: _ctx.nodeClick,
        "default-expanded-keys": _ctx.treeParam.expandKeys,
        "filter-node-method": _ctx.filterTree
      }, null, 8, ["data", "props", "onNodeClick", "default-expanded-keys", "filter-node-method"])
    ], 8, _hoisted_2)), [
      [_directive_loading, _ctx.treeParam.treeLoadFlag]
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "second",
      "element-loading-text": _ctx.$t('mainLoadMsg')
    }, [
      _createVNode(_component_GridTable, _mergeProps({
        ref: el=>_ctx.refMap.set('gridRef1',el)
      }, _ctx.gridParam), {
        tbCols: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "F_CODE",
            label: _ctx.$t('area.F_CODE'),
            align: "left",
            width: "200"
          }, null, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            prop: "F_NAME",
            label: _ctx.$t('area.F_NAME'),
            align: "left"
          }, null, 8, ["label"])
        ]),
        _: 1
      }, 16)
    ], 8, _hoisted_3)), [
      [_directive_loading, _ctx.gridParam.gridLoadFlag]
    ])
  ]))
}